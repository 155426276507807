



















































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import {
  downloadNewsExcel,
  getNewsList,
  getNews,
  downloadNewsRawDatas,
} from '@/api/news';
import { getWeatherTypeList } from '@/api/weather-type';
import Classification from './components/Classification.vue';
import moment from 'moment';

@Component({
  name: 'Dashboard',
  components: {
    Classification,
  },
})

export default class Index extends Vue {
  private newsList:any = [];

  private classificationVisible = false;

  private classificationData = {}

  private totalNews = 0;

  private excelLoading = false;

  private pageable = {
    page: 0,
    size: 20,
    // year: moment().format('YYYY'),
    searchType: '',
    label: 1,
    type: 'title',
    value: '',
    sort: 1,
    daterange: [moment().format('YYYY-01-01'), moment().format('YYYY-MM-DD')],
    classification: -1,
    filterDamage: true,
  };

  private newsMoreLock = false;

  private loading = true;

  private weatherTypes = [];

  private weatherTypeLoading = true;

  private newsUid = '';

  private selectedNewsIndex = 0;

  private rawDataLoading = false;

  private datePickerOptions = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    },
  };

  mounted() {
    this.getNewsList();
    this.getWeatherTypeList();
  }

  private handleSearch() {
    this.newsList = [];
    this.pageable.page = 0;
    this.newsMoreLock = false;
    this.getNewsList();
  }

  private getWeatherTypeList() {
    getWeatherTypeList().then((res) => {
      this.weatherTypes = res.data;
      this.weatherTypeLoading = false;
    }).catch(() => {
      this.$message.error('기상영향 목록을 불러오는데 실패했습니다.');
      this.weatherTypeLoading = false;
    });
  }

  private handleClassification(props: any) {
    this.classificationVisible = !this.classificationVisible;
    this.selectedNewsIndex = props.$index;
    if (props != null) this.newsUid = props.row.uid;
  }

  private handleSuccessClassification() {
    this.classificationVisible = !this.classificationVisible;
    getNews(this.newsUid).then((res) => {
      const relationSentenceSize = this.newsList[this.selectedNewsIndex].sentenceList.length;
      this.newsList.splice(this.selectedNewsIndex, relationSentenceSize);
      if (this.pageable.label === res.data.label && (this.pageable.classification === res.data.classification || this.pageable.classification === -1)) {
        const convertedNewsList = this.convertNewsList([res.data]);
        this.newsList.splice(this.selectedNewsIndex, 0, ...convertedNewsList);
      }
    });
  }

  private handleDeleteNews() {
    this.classificationVisible = !this.classificationVisible;
    const relationSentenceSize = this.newsList[this.selectedNewsIndex].sentenceList.length;
    this.newsList.splice(this.selectedNewsIndex, relationSentenceSize);
  }

  private goNews(link: string) {
    window.open(link);
  }

  private getNewsList() {
    this.loading = true;
    const listQuery = {
      ...this.pageable,
      daterange: null,
      startDate: this.pageable.daterange[0] || '',
      endDate: this.pageable.daterange[1] || '',
    };
    getNewsList(listQuery).then((res: any) => {
      // this.newsList = [...this.newsList, ...res.data.content];
      this.newsList = this.newsList.concat(this.convertNewsList(res.data.content));
      if (res.data.content.length === 0) this.newsMoreLock = true;
      this.totalNews = res.data.totalElements;
      this.loading = false;
    });
  }

  private handleNewsMore() {
    if (this.loading || this.newsMoreLock) return;
    this.pageable = {
      ...this.pageable,
      page: this.pageable.page + 1,
    };
    this.getNewsList();
  }

  /* eslint-disable */
  private convertNewsList(itemList: any) {
    const newsList: any = [];
    itemList.forEach((news: any) => {
      const relationSentenceSize = news.sentenceList.length;
      if (relationSentenceSize === 0) {
        newsList.push({
          ...news,
          sentence: '데이터가 없습니다.',
          words: [],
          viewOrder: 0,
          sentenceSize: 1,
          isEmpty: true,
        });
      } else {
        let viewIndex = 0;
        news.sentenceList.forEach((sentence: any, index: number) => {
          const item = {
            ...news,
            sentence: sentence.sentence,
            words: sentence.damage == null ? '' : sentence.damage.split(','),
            causes: sentence.cause == null ? '' : sentence.cause.split(','),
            area: news.area == null ? '' : news.area.split(','),
            district: sentence.district == null ? '' : sentence.district.split(','),
            city: sentence.city == null ? '' : sentence.city.split(','),
            dong: sentence.dong == null ? '' : sentence.dong.split(','),
            summaryDamange: sentence.summaryDamange = null ? '' : sentence.summaryDamange,
            viewOrder: viewIndex,
            damageUnit: sentence.damageUnit == null ? '' : sentence.damageUnit.split(','),
            sentenceSize: relationSentenceSize,
          };
          newsList.push(item);
          viewIndex += 1;
        });
      }
    });
    return newsList;
  }
  /* eslint-enable */

  /* eslint-disable */
  private objectSpanMethod({ row, column, rowIndex, columnIndex }: any) {
    let span = [0, 0];
    if (row.viewOrder === 0) {
      span = [row.sentenceSize, 1];
    }
    // if (columnIndex ===  4) span = [1, 1];
    if (columnIndex ===  5) span = [1, 1];
    if (columnIndex ===  6) span = [1, 1];
    if (columnIndex ===  7) span = [1, 1];
    if (columnIndex ===  8) span = [1, 1];
    if (columnIndex ===  9) span = [1, 1];
    if (columnIndex ===  10) span = [1, 1];
    if (columnIndex ===  11) span = [1, 1];
    // if (columnIndex ===  8) span = [1, 1];
    // if (columnIndex ===  4) span = [1, 1];
    // if (columnIndex ===  5) span = [1, 1];
    // if (row.isEmpty) {
    //   if (columnIndex ===  3) span = [1, 2];
    // }
    return span;
  }
  /* eslint-enable */

  private handleDownloadExcel() {
    const listQuery = {
      ...this.pageable,
      daterange: null,
      startDate: this.pageable.daterange[0] || '',
      endDate: this.pageable.daterange[1] || '',
    };
    this.excelLoading = true;
    downloadNewsExcel(listQuery).then((res) => {
      const fileURL = window.URL.createObjectURL(new Blob([res.data], { type: 'application/csv;charset=utf-8;' }));
      const fileLink = document.createElement('a');
      let fileName = '기상영향 통계.xls';
      if (res.headers['content-disposition']) {
        const message = res.headers['content-disposition'];
        fileName = decodeURI(message.split('=')[1]).replaceAll('+', ' ');
      }
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (navigator as any).msSaveBlob(new Blob([res.data], { type: 'application/csv;charset=utf-8;' }), fileName);
      } else {
        fileLink.href = fileURL;
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
      this.excelLoading = false;
    }).catch(() => {
      this.$message.error('통계 다운로드를 실패했습니다.');
      this.excelLoading = false;
    });
  }

  private handleDownloadRawDatas() {
    const listQuery = {
      ...this.pageable,
      daterange: null,
      startDate: this.pageable.daterange[0] || '',
      endDate: this.pageable.daterange[1] || '',
    };
    this.rawDataLoading = true;
    downloadNewsRawDatas(listQuery).then((res) => {
      const fileURL = window.URL.createObjectURL(new Blob([res.data], { type: 'application/csv;charset=utf-8;' }));
      const fileLink = document.createElement('a');
      let fileName = '기상영향 원시 데이터.xls';
      if (res.headers['content-disposition']) {
        const message = res.headers['content-disposition'];
        fileName = decodeURI(message.split('=')[1]).replaceAll('+', ' ');
      }
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (navigator as any).msSaveBlob(new Blob([res.data], { type: 'application/csv;charset=utf-8;' }), fileName);
      } else {
        fileLink.href = fileURL;
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
      this.rawDataLoading = false;
    }).catch(() => {
      this.$message.error('원시 데이터 다운로드를 실패했습니다.');
      this.rawDataLoading = false;
    });
  }

  private handleClickDateTool(index: number) {
    const today = moment().format('YYYY-MM-DD');
    if (index === 0) this.pageable.daterange = [moment().subtract(1, 'days').format('YYYY-MM-DD'), today];
    if (index === 1) this.pageable.daterange = [moment().subtract(1, 'weeks').format('YYYY-MM-DD'), today];
    if (index === 2) this.pageable.daterange = [moment().subtract(1, 'months').format('YYYY-MM-DD'), today];
    if (index === 3) this.pageable.daterange = [moment().subtract(6, 'months').format('YYYY-MM-DD'), today];
    this.handleSearch();
  }
}

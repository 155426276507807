import { render, staticRenderFns } from "./Classification.vue?vue&type=template&id=71b355b8&scoped=true&"
import script from "./Classification.vue?vue&type=script&lang=ts&"
export * from "./Classification.vue?vue&type=script&lang=ts&"
import style0 from "./Classification.vue?vue&type=style&index=0&id=71b355b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71b355b8",
  null
  
)

export default component.exports












































import { getPosDictionaryByWord, savePosWordByNewsWord } from '@/api/pos-dictionary';
import {
  addWordDictionary,
  getWordDictionary,
  getWordDictionaryList,
  getWordDictionaryListAll,
  isUsableWord,
} from '@/api/word-dictionary';
import { ElForm } from 'element-ui/types/form';
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';

@Component({
  name: 'PosForm',
})
export default class extends Vue {
  @Prop({ required: true }) private selectedWord!: any;

  mounted() {
    this.getPosDictionary();
    this.getWordDictionaries();
    this.form = {
      ...this.form,
      pos: this.selectedWord.word,
      tag: this.selectedWord.tag,
    };
  }

  private wordValidator(rules: any, value: string, callback: Function) {
    if (value) callback();
    else callback(new Error('대표 단어를 입력하세요.'));
  }

  private form = {
    wdTag: 'NNG',
    word: '',
    pos: '',
    tag: '',
  }

  private wordDictionaries: any = [];

  private selectLoading = true;

  private rules = {
    word: [
      { validator: this.wordValidator, trigger: 'blur' },
    ],
  }

  private getPosDictionary() {
    const listQuery = {
      page: 1,
      size: 1,
      pos: this.selectedWord.word,
    };
    getWordDictionaryList(listQuery).then((res) => {
      if (res.data.content.length > 0) this.form.word = res.data.content[0].word;
    });
  }

  private getWordDictionaries() {
    getWordDictionaryListAll().then((res) => {
      this.wordDictionaries = res.data;
      this.selectLoading = false;
    });
  }

  private handleSubmit() {
    (this.$refs.posForm as ElForm).validate((valid: boolean) => {
      if (valid) {
        savePosWordByNewsWord(this.form).then((res) => {
          this.$message.success('성공적으로 형태소를 저장했습니다.');
          this.handleClose();
        });
      }
    });
  }

  private handleAddWord() {
    const newWord = (this.$refs.wordSelect as any).query;
    isUsableWord(newWord).then((res) => {
      if (res.data) {
        addWordDictionary({ word: newWord, tag: this.form.wdTag }).then(() => {
          this.wordDictionaries.push(newWord);
          this.form.word = newWord;
        });
      }
    });
  }

  private handleClose() {
    this.$emit('close');
  }
}

import request from '@/util/request';

const PATH = '/news';

export const getNewsList = (pageable: any) => request({
  url: `${PATH}/list`,
  method: 'get',
  params: pageable,
});

export const getNews = (uid: string) => request({
  url: `${PATH}/${uid}`,
  method: 'get',
});

export const downloadNewsExcel = (pageable: any) => request({
  url: `${PATH}/excel`,
  method: 'get',
  params: pageable,
  responseType: 'blob',
});

export const downloadNewsRawDatas = (pageable: any) => request({
  url: `${PATH}/raw-datas`,
  method: 'get',
  params: pageable,
  responseType: 'blob',
});

export const getClassificationData = (uid: string) => request({
  url: `${PATH}/${uid}/classification`,
  method: 'get',
});

export const deleteNews = (uid: string) => request({
  url: `${PATH}/${uid}`,
  method: 'delete',
});

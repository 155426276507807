










































































import { classification } from '@/api/learning';
import { getClassificationData, deleteNews } from '@/api/news';
import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import PosSetting from './PosSetting.vue';

@Component({
  name: 'Classfication',
  components: {
    PosSetting,
  },
})

export default class extends Vue {
  @Prop({ required: true }) private newsUid!: string;

  mounted() {
    this.getClassificationData();
  }

  private loading = true;

  private classificationData: any = [];

  private posSettingVisible = false;

  private selectedSentenceUid: string|null = null;

  private deleteNews() {
    this.$confirm('정말 삭제하시겠습니까?', '경고', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning',
    }).then(() => {
      deleteNews(this.newsUid).then(() => {
        this.$message({
          type: 'success',
          message: '삭제되었습니다.',
        });
        this.$emit('delete');
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '실패하였습니다.',
        });
      });
    });
  }

  private getClassificationData() {
    getClassificationData(this.newsUid).then((res) => {
      this.classificationData = res.data;
      this.loading = false;
    });
  }

  private handlePosSetting(uid: string|null) {
    this.selectedSentenceUid = uid;
    this.posSettingVisible = !this.posSettingVisible;
  }

  private handleChangeNewsLearningLabel(label: number) {
    if (label === 0) {
      this.$confirm('정말 해당 뉴스를 기상과 관련 없는 데이터로 분류하시겠습니까?', '경고', {
        confirmButtonText: '예',
        cancelButtonText: '아니오',
        type: 'warning',
      }).then(() => {
        this.classificationData.label = 0;
        this.classificationData.sentenceList.forEach((sentence: any) => {
          /* eslint-disable */
          sentence.label = 0;
          sentence.learningLabel = 0;
          /* eslint-enable */
        });
      }).catch(() => {
        this.classificationData.label = 1;
        this.classificationData.learningLabel = 1;
      });
    }
  }

  private handleChangeNewsLabel(label: number) {
    if (label === 0) {
      this.$confirm('정말 해당 뉴스를 기상과 관련 없는 데이터로 분류하시겠습니까?', '경고', {
        confirmButtonText: '예',
        cancelButtonText: '아니오',
        type: 'warning',
      }).then(() => {
        this.classificationData.sentenceList.forEach((sentence: any) => {
          /* eslint-disable */
          sentence.label = 0;
          /* eslint-enable */
        });
      }).catch(() => {
        this.classificationData.label = 1;
      });
    }
  }

  private handleClassification() {
    this.$confirm('정말 제출하시겠습니까?', '경고', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning',
    }).then(() => {
      this.loading = true;
      classification(this.newsUid, this.classificationData).then(() => {
        this.$message.success('성공적으로 분류하였습니다.');
        this.$emit('success');
        this.loading = false;
      }).catch(() => {
        this.$message.error('분류에 실패하였습니다.');
        this.loading = false;
      });
    });
  }

  private handleClose() {
    this.$emit('close');
  }
}

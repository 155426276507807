


































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import { getNewsSentence } from '@/api/news-sentence';
import PosForm from './PosForm.vue';
import { getPosDictionaryList } from '@/api/pos-dictionary';

@Component({
  name: 'PosSetting',
  components: {
    PosForm,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private selectedSentenceUid!: string|null;

  private sentence: any = {
    sentence: '',
    words: [],
  };

  private loading = true;

  private selectedWord: any = null;

  private formVisible = false;

  private posDictionaries: any = [];

  mounted() {
    this.getNewsSentence();
    this.getPosDictionaries();
  }

  private getNewsSentence() {
    getNewsSentence(this.selectedSentenceUid).then((res) => {
      this.sentence = res.data;
      this.loading = false;
    });
  }

  private getPosDictionaries() {
    getPosDictionaryList().then((res) => {
      this.posDictionaries = res.data;
    });
  }

  private handleVisibleForm(word: any) {
    this.formVisible = !this.formVisible;
    this.selectedWord = word;
  }

  private getTagType(word: any) {
    const posIndex = this.posDictionaries.findIndex((pos: any) => pos.word === word.word);
    if (posIndex > -1) {
      const pos = this.posDictionaries[posIndex];
      if (pos.wdUid) return 'primary';
      return 'success';
    }
    return 'info';
  }
}
